const colorSchemes = [
  [
    "#A01C32",
    "#1D1F20",
    "#F1F3F8",
    "#5062AA"
  ],
  [
    "#46A46C",
    "#62676A",
    "#341A04",
    "#A5DCE4"
  ],
  [
    "#445626",
    "#ff7400",
    "#ffffff",
    "#bbc08a"
  ],
  [
    "#000000",
    "#D6E9F5",
    "#E9F7F9",
    "#B7BBBD"
  ],
  [
    "#444444",
    "#ffc93c",
    "#d9d9d9",
    "#444444"
  ],
  [
    "#000000",
    "#ffffff",
    "#000000",
    "#d9d9d9"
  ],
  [
    "#276D9B",
    "#F4A15D",
    "#F7F1F8",
    "#404E88"
  ],
  [
    "#F3BAC3",
    "#D6E9F5",
    "#B0DDC2",
    "#0C2427"
  ],
  [
    "#341A04",
    "#A5AED5",
    "#0C2427",
    "#79C698"
  ],
  [
    "#6373B6",
    "#DBC1E1",
    "#1D1F20",
    "#D6E9F5"
  ],
  [
    "#6340b5",
    "#fafafa",
    "#6340b5",
    "#fafafa"
  ],
  [
    "#f9ed69",
    "#f08a5d",
    "#b83b5e",
    "#d9d9d9"
  ],
  [
    "#ffc7c7",
    "#8785a2",
    "#ffe2e2",
    "#f6f6f6"
  ],
  [
    "#000000",
    "#C9A2D2",
    "#0F2417",
    "#F7F7F8"
  ],
  [
    "#283c63",
    "#f85f73",
    "#928a97",
    "#fbe8d3"
  ],
  [
    "#a8d8ea",
    "#aa96da",
    "#fcbad3",
    "#ffffd2"
  ],
  [
    "#07689f",
    "#ff7e67",
    "#a2d5f2",
    "#fafafa"
  ],
  [
    "#79C698",
    "#6373B6",
    "#DBC1E1",
    "#2D1832"
  ],
  [
    "#8785a2",
    "#f6f6f6",
    "#ffe2e2",
    "#ffc7c7"
  ],
  [
    "#ffcfdf",
    "#fefdca",
    "#e0f9b5",
    "#a5dee5"
  ]
]

export default colorSchemes
